<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <card class="card-plain">
          <!-- <template slot="header">
            <h4 class="card-title">Table on Plain Background</h4>
            <p class="category">Here is a subtitle for this table</p>
          </template>-->
          <div class="table-full-width text-left">
            <powerStrip-table
            v-if="powerStrips && powerStrips.length > 0"
				:title="table1.title"
				:sub-title="table1.subTitle"
				:powerStrips="powerStrips"
				:totalElements="totalElements"
				:page="page"
				:columns="table1.columns"
 				@get-all-power-strips="getAllPowerStrips"
             >

            </powerStrip-table>
          </div>
        </card>
      </div>

    </div>
  </div>
</template>
<script>
import { Card } from "@/components/Card";

import PowerStripTable from "@/components/PowerStripTable";
import PowerStripService from "../services/PowerStripService";

const tableColumns = [];
const tableData = [
];

export default {
  components: {
    Card,
    PowerStripTable,
  },
  data() {
    return {
      powerStrips: [],
	  totalElements: 0,
      page: 0,
      searchQuery: '',     
      table1: {
        title: "Simple Table",
        columns: [...tableColumns],
        data: [...tableData],
      },
    };
  },
  methods: {
    async getAllPowerStrips(sortBy='powerStripId',sortOrder='true',page="0",size="50") {
      try {
        try {
			const searchDTO = {
				sortBy: sortBy, 
				sortOrder: sortOrder, 
				searchQuery: this.searchQuery,
				page: page, 
				size: size
			};
	        let response = await PowerStripService.getAllPowerStrips(searchDTO);
	        if (!response.data.empty) {
		        if (response.data.powerStrips.length) {
					this.powerStrips = response.data.powerStrips;
				}
      			this.totalElements = response.data.totalElements;
      			this.page = response.data.page;
	        }
        } catch (error) {
          console.error("Error fetching powerStrips:", error);
        }
        
      } catch (error) {
        console.error("Error fetching powerStrip details:", error);
      }
    },
  },
  mounted() {
    this.getAllPowerStrips();
  },
  created() {
    this.$root.$on('searchQueryForPowerStripsChanged', (searchQuery) => {
    	this.searchQuery = searchQuery;
    	this.getAllPowerStrips();
    })
  }
};
</script>
<style></style>
