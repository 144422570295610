import { render, staticRenderFns } from "./PowerSupplyDetail.vue?vue&type=template&id=0cf8d2ec"
import script from "./PowerSupplyDetail.vue?vue&type=script&lang=js"
export * from "./PowerSupplyDetail.vue?vue&type=script&lang=js"
import style0 from "./PowerSupplyDetail.vue?vue&type=style&index=0&id=0cf8d2ec&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports